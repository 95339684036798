import { useCallback, useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { decode } from "@msgpack/msgpack";
import "./App.css";

// const CanvasJSReact: any = require("@canvasjs/react-charts");
const CanvasJSReact = require("@canvasjs/react-stockcharts");

const [_TIMESTAMP_IDX, _OPEN_IDX, _HI_IDX, _LO_IDX, CLOSE_IDX] = [0, 1, 2, 3, 4];
const DOMAIN = process.env.REACT_APP_STREAM_DOMAIN ?? "stream.spotgamma.com";
console.log("domain: ", DOMAIN);

// var CanvasJS: any = CanvasJSReact.CanvasJS;
const CanvasJSStockChart: any = CanvasJSReact.default.CanvasJSStockChart;

const CHOICES = [
  "__FAKE__",
  "/ESU24:XCME",
  "Mag7",
  "EXPE",
  "SNDL",
  "SPY",
  "S&P Equities",
  "S&P 500",
  "TSLA",
];

interface DataPt {
  x: number;
  y: number;
}

type CandleTuple = [
  BigInt, // Timestamp
  number, // open
  number, // high
  number, // low
  number, // close
];

type FullHiroTuple = [
  string, //  0: underlying
  BigInt, //  1: time
  number, //  2: delta_notional
  number, //  3: gamma_notional
  number, //  4: vega_notional
  number, //  5: stock_price
  number, //  6: tns_index
  BigInt, //  7: expiry
  number, //  8: strike
  number, //  9: size
  number, // 10: flags
  string, // 11: exchange sale conditions
  number, // 12: price
  number, // 13: bid
  number, // 14: ask
  number, // 15: ivol
];

type HiroTuple = [
  string,
  BigInt, // timestamp
  number,
  number,
  number,
  number,
  BigInt, // sequence
  BigInt, // timestamp
  number, // strike
  number,
];

enum StreamId {
  FILTERED_DELTA = 0x000001,
  ABS_DELTA = 0x000002,
  PRICES = 0x000004,

  // ALL
  CANDLE_FILTERED_TOTAL_DELTA = 0x000008,
  CANDLE_ABS_TOTAL_DELTA = 0x000010,
  CANDLE_FILTERED_CALL_DELTA = 0x000020,
  CANDLE_FILTERED_PUT_DELTA = 0x000040,

  // NextExp
  CANDLE_FILTERED_NEXTEXP_TOTAL_DELTA = 0x00080,
  CANDLE_ABSOLUTE_NE_TOTAL_DELTA = 0x00100,
  CANDLE_FILTERED_NE_CALL_DELTA = 0x00200,
  CANDLE_FILTERED_NE_PUT_DELTA = 0x00400,

  // Retail
  CANDLE_FILTERED_RET_TOTAL_DELTA = 0x00800,
  CANDLE_ABSOLUTE_RET_TOTAL_DELTA = 0x01000,
  CANDLE_FILTERED_RET_CALL_DELTA = 0x02000,
  CANDLE_FILTERED_RET_PUT_DELTA = 0x04000,

  // Volume
  UNDERLYING_VOLUME = 0x08000,

  // Full absolute signal (price, bid, ask, & ivol)
  FULL_ABSOLUTE_SIGNAL = 0x10000,
}

const TO_LISTEN: number =
  StreamId.FILTERED_DELTA |
  StreamId.PRICES |
  StreamId.CANDLE_FILTERED_TOTAL_DELTA |
  StreamId.FULL_ABSOLUTE_SIGNAL;

interface DataObj {
  timestamp: number;
  delta: number;
  gamma: number;
  stockPrice: number;
}

function toDataObj(signal: HiroTuple): DataObj {
  const [
    _symbol,
    timestamp,
    delta,
    gamma,
    _vega,
    stockPrice,
    _sequence,
    _expiry,
    _strike,
    _flags,
  ]: HiroTuple = signal;
  return { timestamp: Number(timestamp), delta, gamma, stockPrice };
}

// FIFO queue
class VecDequeue<T> {
  queue: T[];
  startIdx: number;
  endIdx: number;

  constructor(len: number) {
    this.queue = Array(len);
    this.startIdx = 0;
    this.endIdx = 0;
  }

  // Happily overwrites any element in the FIFO queue if we run out of space,
  // returning that element
  push(elem: T) {
    this.endIdx = (this.endIdx + 1) % this.queue.length;
    let popped = null;
    if (this.endIdx === this.startIdx) {
      popped = this.queue[this.startIdx];
      this.startIdx = (this.startIdx + 1) % this.queue.length;
    }
    this.queue[this.endIdx] = elem;
    return popped;
  }

  pop() {
    if (this.startIdx === this.endIdx) {
      // empty
      return null;
    }
    this.startIdx = (this.startIdx + 1) % this.queue.length;
    return this.queue[this.startIdx];
  }
}

// At the expense of 2x the storage, keep a FIFO queue with which to remove
// items in FIFO order from our tracking set
// Keep at most 2k keys tracked
const LIMIT = 2_000;
class Deduper<T> {
  fifo: VecDequeue<T>;
  keySet: Set<T>;

  constructor() {
    this.fifo = new VecDequeue(LIMIT);
    this.keySet = new Set();
  }

  add(elem: T) {
    const popped = this.fifo.push(elem);
    this.keySet.add(elem);
    if (popped != null) {
      this.keySet.delete(popped);
    }
  }

  has(elem: T): boolean {
    return this.keySet.has(elem);
  }
}

async function fetchCandles(url: string, headers: any): Promise<CandleTuple[]> {
  const resp = await fetch(url, headers);
  const buf: ArrayBuffer = await resp.arrayBuffer();
  return decode(buf, { useBigInt64: true }) as CandleTuple[];
}

interface LoginResult {
  status: number;
  text: string;
}

async function login(user: string, pass: string): Promise<LoginResult> {
  const encodedUserPass = btoa(`${user}:${pass}`);
  const authResult = await fetch(`https://${DOMAIN}/auth`, {
    headers: {
      Authorization: `Basic ${encodedUserPass}`,
    },
  });
  const text = await authResult.text();
  return { status: authResult.status, text };
}

function getStreamLabel(stream: number) {
  switch (stream) {
    case StreamId.FILTERED_DELTA:
      return "Filtered TnS";
    case StreamId.ABS_DELTA:
      return "Absolulte TnS";
    case StreamId.PRICES:
      return "Absolute price candle";
    case StreamId.CANDLE_FILTERED_TOTAL_DELTA:
      return "Filtered delta candle";
    case StreamId.CANDLE_ABS_TOTAL_DELTA:
      return "Absolute delta candle";
    case StreamId.FULL_ABSOLUTE_SIGNAL:
      return "Full Absolute Signal";
    default:
      return `<UNEXEPCTED> ${stream}`;
  }
}

function App() {
  const dataRef: { current: any[] } = useRef([]);
  const deduperRef: { current: Deduper<string> } = useRef(
    new Deduper<string>(),
  );
  const dupeRef: { current: number } = useRef(0);
  const ignored = useRef(new Set());
  const [targetSym, setTarget] = useState<string>(CHOICES[0]);
  const [, setUser] = useState<string | null>(null);
  const [, setPass] = useState<string | null>(null);
  const [loginErr, setLoginErr] = useState<string | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [priceDataPts, setPriceDataPts] = useState<DataPt[]>([]);
  const [deltaDataPts, setDeltaDataPts] = useState<DataPt[]>([]);
  const [gammaDataPts, setGammaDataPts] = useState<DataPt[]>([]);
  const [searchParams, _setSearchParams] = useSearchParams();

  const targetSymRef = useRef(targetSym);

  useEffect(() => {
    const fetchData = async() => {
      const token = localStorage.getItem('token');
      if (token != null) {
        const headers = { headers: { Authorization: `Bearer ${token}` } };
        const resp = await fetch(`https://${DOMAIN}/validate_bearer`, headers);
        if (resp.ok) {
          // Go ahead and use the token to connect
          console.log('using localStorage token');
          setAuthToken(token);
        } else {
          localStorage.removeItem('token');
          const text = await resp.text();
          console.error(text);
        }
      }
    };
    fetchData();
  }, []);

  const processSignal = useCallback((signal: HiroTuple) => {
    const [
      symbol,
      _timestamp,
      _delta,
      _gamma,
      _vega,
      _stockPrice,
      sequence,
      expiry,
      strike,
      _flags,
    ]: HiroTuple = signal;
    const sidx = symbol.indexOf("|");
    const sym = sidx >= 0 ? symbol.slice(0, sidx) : symbol;
    const key = `${symbol}:${sequence}:${expiry}:${strike}`;
    if (sym !== targetSymRef.current) {
      ignored.current.add(symbol);
      // @ts-ignore
      window["__ignored"] = ignored;
      return null;
    } else if (deduperRef.current.has(key)) {
      dupeRef.current++;
      return null;
    }
    deduperRef.current.add(key);
    return signal;
  }, []);

  useEffect(() => {
    if (authToken == null) {
      dataRef.current = [];
      return;
    }
    const fetchData = async (sym: string) => {
      const end = new Date().valueOf(); // NOTE: this can encounter overlaps with streaming, but Deduper will handle overlaps
      const headers = { headers: { Authorization: `Bearer ${authToken}` } };
      const priceSym = sym === encodeURIComponent("S&P Equities") ? "SPX" : sym;
      const [deltas, prices, gammas] = (await Promise.all([
        fetchCandles(
          `https://${DOMAIN}/candles?sym=${sym}&end=${end}`,
          headers,
        ),
        fetchCandles(
          `https://${DOMAIN}/candles?sym=${priceSym}&end=${end}&field=price`,
          headers,
        ),
        fetchCandles(
          `https://${DOMAIN}/candles?sym=${sym}&end=${end}&field=gamma`,
          headers,
        ),
      ])) as [CandleTuple[], CandleTuple[], CandleTuple[]];
      if (deltas.length !== gammas.length) {
        console.error(deltas.length, gammas.length);
        throw new Error("unexpected array differences");
      }
      if (prices.length === 0) {
        console.error("candle prices came back empty");
        return;
      }
      let lastPrice = prices[0]?.[CLOSE_IDX];
      const time2price = new Map(
        prices.map(([ts, _h, _l, price]) => [ts, price]),
      );
      const prefix = deltas.map(([timestamp, _open, _hi, _lo, deltaClose], idx) => {
        lastPrice = time2price.get(timestamp) ?? lastPrice;
        return {
          timestamp: Number(timestamp),
          delta: deltaClose,
          gamma: gammas[idx][CLOSE_IDX],
          stockPrice: lastPrice,
        };
      });
      dataRef.current = prefix.concat(dataRef.current);
    };
    fetchData(encodeURIComponent(targetSym));
  }, [authToken, targetSym]);

  useEffect(() => {
    let token = searchParams.get("token");
    if (token != null) {
      console.log("using query auth token: ", token);
      setAuthToken(token);
    }
    const queryUser = searchParams.get("user");
    if (queryUser != null) {
      setUser(queryUser);
    }
    let password = searchParams.get("password");
    if (password != null) {
      setPass(password);
    }
  }, [searchParams]);

  useEffect(() => {
    const subscribeToStream = async () => {
      if (authToken == null) {
        return;
      }

      const socket = new WebSocket(`wss://${DOMAIN}/stream?token=${authToken}`);

      socket.addEventListener("open", function (_evt) {
        console.log("open, sending msg");
        const msg = {
          action: "subscribe",
          underlyings: CHOICES,
          stream_types: TO_LISTEN, // filtered delta, prices, filtered delta candles
        };
        socket.send(JSON.stringify(msg));
      });

      let numMsgs = 0;

      socket.addEventListener("message", async function (event) {
        const { data } = event;
        if (event.data instanceof Blob) {
          numMsgs += 1;
          const signalTuple: any = decode(await data.arrayBuffer(), {
            useBigInt64: true,
          });
          const [stream, signal] = signalTuple;
          if (StreamId.FULL_ABSOLUTE_SIGNAL === stream) {
            console.log(getStreamLabel(stream), signal);
          }
          if (StreamId.FILTERED_DELTA !== stream) {
            return;
          }

          const tuple: HiroTuple | null = processSignal(signal);
          if (tuple == null) {
            return;
          }
          dataRef.current.push(toDataObj(tuple));
          if (numMsgs < 10) {
            console.log(signalTuple);
          }
        } else {
          console.log("Text Message from server ", event);
        }
      });

      setInterval(() => {
        console.log("Messages received: ", numMsgs);
        console.log("data len: ", dataRef.current.length);
        console.log("Duplicate sequences", dupeRef.current);
      }, 10000);

      setInterval(() => {
        const prices = [];
        const deltas = [];
        const gammas = [];
        let delta = 0;
        let gamma = 0;
        for (const elem of dataRef.current) {
          prices.push({ x: elem.timestamp, y: elem.stockPrice });
          delta += elem.delta;
          deltas.push({ x: elem.timestamp, y: delta });
          gamma += elem.gamma;
          gammas.push({ x: elem.timestamp, y: gamma });
        }
        setPriceDataPts(prices);
        setDeltaDataPts(deltas);
        setGammaDataPts(gammas);
      }, 1000);

      socket.addEventListener("error", function (event) {
        console.log("!!! Got error !!!");
        console.log("error event:", event);
      });

      socket.addEventListener("close", function (event) {
        console.log("!!! Socket closed !!!");
        console.log("close event:", event);
      });
    };

    subscribeToStream();
  }, [authToken, setPriceDataPts, processSignal]);

  // clear our data when we change symbols
  useEffect(() => {
    dataRef.current = [];
    setPriceDataPts([]);
    setDeltaDataPts([]);
    setGammaDataPts([]);
  }, [targetSym]);

  const options = {
    title: {
      text: `${targetSym} Delta Notional`,
    },
    // animationEnabled: true,
    exportEnabled: true,
    charts: [
      {
        axisX: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
        },
        axisY: [
          {
            title: "Price",
            crosshair: {
              enabled: true,
              //snapToDataPoint: true
            },
          },
        ],
        axisY2: [
          {
            title: "Delta Notional",
            crosshair: {
              enabled: true,
              //snapToDataPoint: true
            },
          },
        ],
        data: [
          {
            type: "line",
            xValueType: "dateTime",
            dataPoints: priceDataPts,
          },
          {
            type: "spline",
            axisYType: "secondary",
            xValueType: "dateTime",
            dataPoints: deltaDataPts,
          },
        ],
      },
    ],
  };

  const gammaOpts = {
    title: {
      text: `${targetSym} Gamma Notional`,
    },
    // animationEnabled: true,
    exportEnabled: true,
    charts: [
      {
        axisX: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
        },
        axisY: [
          {
            title: "Price",
            crosshair: {
              enabled: true,
              //snapToDataPoint: true
            },
          },
        ],
        axisY2: [
          {
            title: "Gamma Notional",
            crosshair: {
              enabled: true,
              //snapToDataPoint: true
            },
          },
        ],
        data: [
          {
            type: "line",
            xValueType: "dateTime",
            dataPoints: priceDataPts,
          },
          {
            type: "spline",
            axisYType: "secondary",
            xValueType: "dateTime",
            dataPoints: gammaDataPts,
          },
        ],
      },
    ],
  };

  const containerProps = {
    width: "100%",
    height: "450px",
    margin: "auto",
  };

  const onSubmit = useCallback((evt: any) => {
    evt.preventDefault();
    const formData = Object.fromEntries(new FormData(evt.target).entries());
    if (!formData.user || !formData.password) {
      return setLoginErr("Please set both user & password");
    }
    const process = async () => {
      let resp = await login(
        formData.user as string,
        formData.password as string,
      );
      if (resp.status >= 300) {
        return setLoginErr(resp.text);
      }
      console.log(resp);
      setAuthToken(resp.text);
    };
    process();
  }, []);

  const body =
    authToken == null ? (
      <div>
        <div>
          <form onSubmit={onSubmit}>
            <table style={{ marginLeft: "auto", marginRight: "auto" }}>
              <tbody>
                <tr>
                  <td align="right">
                    <label htmlFor="user">User: </label>
                  </td>
                  <td>
                    <input
                      autoComplete="username"
                      size={45}
                      id="user"
                      name="user"
                      onChange={(evt: any) => {
                        setUser(evt.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td align="right">
                    <label htmlFor="password">Password: </label>
                  </td>
                  <td>
                    <input
                      autoComplete="current-password"
                      size={45}
                      type="password"
                      id="password"
                      name="password"
                      onChange={(evt) => {
                        setPass(evt.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <input
                      style={{ padding: "4px 16px" }}
                      type="submit"
                      value="LOGIN"
                    />
                  </td>
                </tr>
                {loginErr && (
                  <tr>
                    <td style={{ color: "red" }}>{loginErr}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </form>
        </div>
      </div>
    ) : (
      <>
        <div>
          <select
            onChange={(evt) => {
              setTarget(evt.target.value);
              targetSymRef.current = evt.target.value;
            }}
          >
            {CHOICES.map((val, idx) => (
              <option key={idx}>{val}</option>
            ))}
          </select>
        </div>
        <div>
          <CanvasJSStockChart
            containerProps={containerProps}
            options={options}
          />
        </div>
        <div>
          <CanvasJSStockChart
            containerProps={containerProps}
            options={gammaOpts}
          />
        </div>
      </>
    );
  return <div className="App">{body}</div>;
}

export default App;
